export const ACTIONS = {
  PageLanding: 'Page - Landing',
  PageAbout: 'Page - About',
  PageNews: 'Page - News',
  PagePress: 'Page - Press',
  Logo: 'Click - Logo',
  Email: 'Click - Email',
  Menu: 'Click - Menu',
  Movie1: 'get-duked',
  Movie2: 'come-to-daddy',
  Movie3: 'nine-days',
  Movie4: 'i-blame-society',
};

export const CATEGORIES = {
  PageLanding: 'Page - Landing',
  PageAbout: 'Page - About',
  PageNews: 'Page - News',
  PagePress: 'Page - Press',
};

export const LABEL = {
  PageLanding: 'Page - Landing',
  PageAbout: 'Page - About',
  PageNews: 'Page - News',
  PagePress: 'Page - Press',
  Logo: 'Click - Logo',
  Email: 'Click - Email',
  Menu: 'Click - Menu',
  Movie1: 'get-duked',
  Movie2: 'come-to-daddy',
  Movie3: 'nine-days',
  Movie4: 'i-blame-society',
};
