import ReactGA from 'react-ga';

import { getAction, getCategory, getLabel } from './utils';
import { ACTIONS, CATEGORIES, LABEL } from './settings';

class TrackingWrapper {
  initialized = false;
  GA_ID = 'UA-192664878-1';
  blockTrack = process.env.ENV !== 'production';

  init() {
    if (this.blockTrack) {
      return;
    }
    this.initialized = true;
    ReactGA.initialize(this.GA_ID, { debug: true });
    ReactGA.ga('set', 'anonymizeIp', true);
    this.pageview();
  }

  pageview() {
    if (this.blockTrack || !this.initialized) {
      return;
    }

    ReactGA.pageview(`${window.location.pathname}${window.location.search}`);
  }

  event(params) {
    if (this.blockTrack || !this.initialized) {
      return;
    }

    ReactGA.event(params);
  }

  timing(params) {
    if (this.blockTrack || !this.initialized) {
      return;
    }
    ReactGA.timing(params);
  }
}

const Tracking = new TrackingWrapper();

export {
  Tracking,
  ACTIONS,
  CATEGORIES,
  LABEL,
  getCategory,
  getAction,
  getLabel,
};
