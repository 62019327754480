import { debounce } from 'utils/debounce';
import { getViewportInfo } from 'utils/dom';
import passiveEvent from 'utils/passiveEvent';
import {
  isIOS,
  isAndroid,
  isDesktop,
  isLandscape,
  isInputFocused,
  isRecentIPadSafari,
  isBrowser,
} from 'utils/platform';

class ResizeService {
  all = [];

  size = this.getSize();

  constructor() {
    if (isBrowser()) {
      this.addListeners();
      this.androidFix();
      this.iosFix();
      this.vhFix();
    }
  }

  addListeners() {
    window.addEventListener(
      isDesktop() ? 'resize' : 'orientationchange',
      this.onResize,
      passiveEvent
    );
  }

  add(handler, run = false) {
    if (!this.all.includes(handler)) {
      this.all.push(handler);
    }
    if (run) handler();
  }

  remove(handler) {
    this.all = this.all.filter(h => h !== handler);
  }

  getSize() {
    return {
      width: isBrowser() ? window.innerWidth : 1440,
      height: isBrowser() ? window.innerHeight : 1024,
      devicePixelRatio: isBrowser() ? window.devicePixelRatio : 1,
      isLandscape: isLandscape(),
    };
  }

  androidFix() {
    // Prevent Android keyboard shrinking the viewport
    if (isAndroid() && !isInputFocused()) {
      const width = window.innerWidth;
      const height = window.innerHeight;

      let viewport = document.querySelector('meta[name=viewport]');
      if (!viewport) {
        viewport = document.createElement('meta');
        viewport.setAttribute('name', 'viewport');
        const root = document.getElementsByTagName('meta')[0];
        root.parentNode.insertBefore(viewport, root);
      }

      viewport.setAttribute(
        'content',
        `height=${height},width=${width},initial-scale=1,maximum-scale=1.0,user-scalable=no`
      );
    }
  }

  iosFix() {
    if ((isIOS() || isRecentIPadSafari()) && !isInputFocused()) {
      document.body.style.minHeight = `${window.innerHeight}px`;
    }
  }

  vhFix() {
    // Base it on the size of the window when there's no software keyboard on screen
    if (!isInputFocused()) {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    }
  }

  onResize = debounce(
    () => {
      // Only update if values change
      const newSize = this.getSize();
      const needsViewportUpdate =
        isAndroid() &&
        (getViewportInfo().width !== newSize.width ||
          getViewportInfo().height !== newSize.height);

      if (
        newSize.width === this.size.width &&
        newSize.height === this.size.height &&
        newSize.devicePixelRatio === this.size.devicePixelRatio &&
        newSize.isLandscape === this.size.isLandscape &&
        !needsViewportUpdate
      )
        return;

      this.androidFix();
      this.iosFix();
      this.vhFix();

      // If the keyboard is open on touch devices
      if (!isDesktop() && isInputFocused()) {
        if (isLandscape()) {
          // In the wrong orientation, dismiss the keyboard, so we can show the rotate screen warning
          const activeElement: any = document.activeElement;
          activeElement.blur();
        }
      }

      this.size = newSize;
      this.all.map(handler => handler());
    },
    !isDesktop() ? 500 : 0, // some mobile browsers only update window.innerHeight when the rotate animation finishes
    true,
    true
  );
}

export default new ResizeService();
