import { ACTIONS, CATEGORIES, LABEL } from './settings';

export const getCategory = (page: string) => {
  switch (page) {
    case '/':
      return CATEGORIES.PageLanding;
    case '/about':
      return CATEGORIES.PageAbout;
    case '/news':
      return CATEGORIES.PageNews;
    case '/press':
      return CATEGORIES.PagePress;
    default:
      return null;
  }
};

export const getAction = (action: string) => {
  switch (action) {
    case 'get-duked':
      return ACTIONS.Movie1;
    case 'come-to-daddy':
      return ACTIONS.Movie2;
    case 'nine-days':
      return ACTIONS.Movie3;
    case 'i-blame-society':
      return ACTIONS.Movie4;
    default:
      return null;
  }
};

export const getLabel = (label: string) => {
  switch (label) {
    case 'get-duked':
      return LABEL.Movie1;
    case 'come-to-daddy':
      return LABEL.Movie2;
    case 'nine-days':
      return LABEL.Movie3;
    case 'i-blame-society':
      return LABEL.Movie4;
    default:
      return null;
  }
};
