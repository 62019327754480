import React, { createContext, ReactNode, useState } from 'react';

interface ProviderProps {
  children: ReactNode | ReactNode[];
}

interface State {
  isOpen: boolean;
  isHovered: number;
  currentFilm: number;
  filmLinks: Array<string>;
  preloaderExited: boolean;
  videoQuality: 'medium' | 'high';
  menuOpen: boolean;
}

interface Patch {
  isOpen?: boolean;
  isHovered?: number;
  currentFilm?: number;
  filmLinks?: Array<string>;
  preloaderExited?: boolean;
  videoQuality?: 'medium' | 'high';
  menuOpen?: boolean;
}

export interface FilmPageContextType {
  state: State;
  setState(patch: Patch): void;
}

const defaultContext: FilmPageContextType = {
  state: {
    isOpen: false,
    isHovered: -1,
    currentFilm: -1,
    filmLinks: [],
    preloaderExited: false,
    videoQuality: 'medium',
    menuOpen: false,
  },
  setState: () => {},
};

export const FilmPageContext: React.Context<FilmPageContextType> =
  createContext<FilmPageContextType>(defaultContext);

export const FilmPageContextProvider = ({
  children,
}: ProviderProps): JSX.Element => {
  const [state, setState] = useState<State>(defaultContext.state);

  const patchState = (patch: Patch) =>
    setState(prevState => ({ ...prevState, ...patch }));

  const contextValue = { state, setState: patchState };

  return (
    <FilmPageContext.Provider value={contextValue}>
      {children}
    </FilmPageContext.Provider>
  );
};
