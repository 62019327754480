export const debounce = (
  // eslint-disable-next-line @typescript-eslint/ban-types
  fn: Function,
  delay = 250,
  hasLeadingCall = false,
  hasTrailingCall = false
) => {
  let timeout;

  const cancel = () => {
    clearTimeout(timeout);
    timeout = null;
  };

  const debounced = (...args) => {
    const shouldCallNow = hasLeadingCall && !timeout;
    const doLater = () => {
      timeout = null;

      if (!hasLeadingCall) fn(...args);
      if (hasTrailingCall) {
        timeout = setTimeout(() => {
          timeout = null;
          fn(...args);
        }, delay);
      }
    };

    clearTimeout(timeout);
    timeout = setTimeout(doLater, delay);

    if (shouldCallNow) fn(...args);
  };

  debounced.cancel = cancel;
  return debounced;
};
