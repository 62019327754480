import { useEffect, useRef, useState } from 'react';

import ResizeService from 'services/resize.service';

const windowSize = () => ResizeService.size;

const useWindowSize = () => {
  const [size, setSize] = useState(windowSize());

  const mountedRef = useRef(true);

  useEffect(() => {
    function onResize() {
      if (mountedRef.current) setSize(windowSize());
    }
    ResizeService.add(onResize);
    return () => {
      ResizeService.remove(onResize);
      mountedRef.current = false;
    };
  }, []);

  return size;
};

export default useWindowSize;
